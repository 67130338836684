/* Add these styles to your CSS file */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Greyed background */
  z-index: 1000; /* Ensure it's above other content */
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1); /* Slightly thicker border */
  border-left-color: #09f;
  border-radius: 50%;
  width: 50px; /* Larger spinner */
  height: 50px;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
